import React from 'react';

function PrivacyPolicy() {

  return (
    <div className="bg-gray flex-grow-1">
      <article className="container py-5">
        <div className="row ">
          <h1 className="col title-one pr-md-4">Privacy policy</h1>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="title-two">Data Storage</h2>
            <p className="text-description">We store events, tasks, notes, and app settings in your own iPhone and iCloud account.
              We use your iCloud to sync your data across your devices. It is optional, and you can opt out of it by turning off iCloud.
              </p>
          </div>
          <div className="col-12">
            <h2 className="title-two">Data Collection</h2>
            <p className="text-description">We neither collect data nor have access to your data.</p>
          </div>
          <div className="col-12">
            <h2 className="title-two">Using device feature</h2>
            <p className="text-description">We may ask you for permission to use Siri and Notifications.
              These feature are used solely for the purpose of improving app experience. We make it clear when and why these features are used.
              It is optional, you can opt out of using them at any time. </p>
          </div>

          <div className="col-12">
            <h2 className="title-two">Changes to this Policy</h2>
            <p className="text-description">We may update our privacy policy at any time. We will notify you by posting the new policy on this page.
             You are advised to review our privacy policy periodically.
            </p>
          </div>
          <div className="col-12">
            <p className="text-description text-secondary">
              Last updated on April 2th 2021.
            </p>
          </div>
        </div>
      </article>
    </div>
  );
}

export default PrivacyPolicy;
