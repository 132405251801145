import React from "react";
import { useEffect } from 'react';

function TermsOfUse() {
  useEffect(() => {
    const element = document.querySelector("article.terms-of-use")
    const script = document.createElement('script');

    script.src = 'https://cdn.iubenda.com/iubenda.js';
    script.async = true;

    element.appendChild(script);

    return () => {
      element.removeChild(script);
    }
  })

  return (
    <div className="bg-gray flex-grow-1">
      <article className="container py-5 terms-of-use">
        <a href="https://www.iubenda.com/terms-and-conditions/10559692"
           className="iubenda-white iubenda-noiframe iubenda-embed iub-no-markup iubenda-noiframe iub-body-embed invisible"
           title="Terms and Conditions">Terms and Conditions</a>
      </article>
    </div>
  );
}

export default TermsOfUse;
