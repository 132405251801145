import React from 'react';
import features from './data/features.json';

function Badge(badge) {
  if (badge) {
    return <small>( {badge} )</small>
  }
}

function Features() {
  const elements = features.map((feature, index) =>
    <section className="col-12 c-md-6 mb-2 mb-md-3 d-flex flex-column px-2" key={index}>
      <div className="radius-5px text-light px-4 py-4 flex-grow-1" style={{"background": feature.background }}>
        <h3 className="title-three">{feature.name} {Badge(feature.badge)}</h3>
        <p className="text-description">{feature.description}</p>
      </div>
    </section>
  );

  return (
    <section className="pt-md-5 bg-gray" id="features">
      <div className="container pt-4">
        <div className="row mb-2">
          <h2 className="col title-two">Features</h2>
        </div>
        <div className="row">
          {elements}
        </div>
      </div>
    </section>
  );
}

export default Features;
