import React from 'react';
import calendarScreen from '../img/iphone-xs-event.png';
import appStore from '../img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

function Hero() {
  return (
    <main className="container py-5">
      <div className="row justify-content-between py-md-5">
        <figure className="col-12 col-lg-auto order-lg-last m-0">
          <img src={calendarScreen} alt="" className="ios-max-height img-responsive"/>
        </figure>
        <div className="col-12 col-lg-8 text-center text-md-left ">
          <h1 className="title-one pr-md-4">Horizon - Class Timetable</h1>
          <p className="text-secondary text-headline">
            Horizon is an easy-to-use and yet a powerful Calendar + To Do app for students at school, collage, or university.
            Whether you have a class every two weeks, a training session every three days, or an exam once a year.
            Horizon keeps track of them in a color filled interface that you can personalise.
          </p>
          <div className="d-flex justify-content-center mt-5">
            <a href="https://apps.apple.com/no/app/horizon-class-timetable/id1508187865?itsct=apps_box_link&itscg=30200" className="d-flex align-items-center btn btn-primary p-4">
              <img src={appStore} alt="" className="h-30px"/>
              <span className="pl-2" style={{fontWeight: 500}} >Download on the App Store</span>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}


export default Hero;
