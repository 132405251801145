import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import '../css/App.css';
import '../css/bootstrap-grid.min.css'

import Header from './Header';
import Navigation from "./Navigation";
import Footer from './Footer'

function App() {
  return (
    <div className="d-flex flex-column h-100">
      <Router >
        <Header />
        <Navigation />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
