import React from "react";
import {Route, Switch} from "react-router-dom";

import Main from './Main';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from  './TermsOfUse';


const Navigation = () => {
  return (
    <Switch>
      <Route path="/privacy-policy" >
        <PrivacyPolicy />
      </Route>
      <Route path="/terms-of-use" >
        <TermsOfUse />
      </Route>
      <Route path="/" >
        <Main />
      </Route>
    </Switch>
  )
}


export default Navigation;
