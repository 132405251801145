import React from 'react';

import Hero from './Hero'
import Features from "./Features";
import FAQ from "./FAQ";

function Main() {
  return (
    <div className="flex-grow-1">
      <Hero />
      <Features />
      <FAQ />
    </div>
  );
}

export default Main;
