import React from 'react';

const smoothScroll = () => {
  document.getElementById("features")
    .scrollIntoView({ behavior: "smooth" })
}

const FeaturesButton = () =>  {
  return (
    <li className="nav-item">
      <button className="btn btn-soft-primary btn-oval" onClick={smoothScroll}>
        <b>Features</b>
      </button>
    </li>
  );
}

export default FeaturesButton
