import React from 'react';
import {Link, withRouter } from "react-router-dom";
import logo from '../img/logo-web.svg';

import FeaturesButton from "./FeaturesButton";

function Header({ location }) {
  return (
    <header className="border-bottom d-flex align-items-center">
      <nav className="container py-md-3">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Link className="d-flex align-items-center flex-nowrap text-dark text-decoration-none" to="/">
              <img src={logo} style={{width: "30px", height: "30px"}} alt="" className="shadow-sm"/>
              <strong className="pl-3">Horizon </strong>
            </Link>
            <ul className="d-flex flex-nowrap list-style-none align-items-center">
              {location.pathname === '/' && <FeaturesButton />}
              <li className="nav-item pl-4 pl-md-5">
                <Link className="link text-dark" to="/privacy-policy">Privacy</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default withRouter(Header);
