import React from 'react';

function App() {

  return (
    <footer className="container mt-auto">
      <div className="row justify-content-between py-4">
        <div className="col-auto">
          <a href="mailto:horizon.mahan.no@gmail.com" className="link text-primary">Contact Us</a>
        </div>
        <div className="col-auto d-flex justify-content-end">
            <span>
               &copy; Horizon - Class Timetable
            </span>
        </div>
      </div>
    </footer>
  );
}

export default App;
