import React from 'react';
import faqs from './data/faqs.json';
import arrowSvg from '../img/arrow.svg'

function FAQ() {


  const toggleHandler = (event) => {
    const details = document.querySelectorAll("details")
    for (let detail of details) {
      if (!detail.contains(event.target)) {
        detail.removeAttribute("open")
      }
    }
  }

  const elements = faqs.map((faq, index) =>
    <details className="col-12 mb-2 mb-md-3 px-2 radius-5px" key={index} open={index === 0} onClick={toggleHandler}>
      <summary className="px-3 px-lg-4 py-2">
        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start">
          <p>{faq.question}</p>
          <figure className="order-md-first d-flex justify-content-center mx-0 my-3 my-md-0 mr-md-3">
            <img src={arrowSvg} alt=""/>
          </figure>
        </div>
      </summary>
      <p className="m-0 px-3 px-lg-4 py-5">{faq.answer}</p>
    </details>
  );

  return (
    <section className="pb-5 py-md-4 bg-gray" id="features">
      <div className="container py-4">
        <div className="row mb-2">
          <h2 className="col title-two">FAQ</h2>
        </div>
        <div className="row">
          {elements}
        </div>
      </div>
    </section>
  );
}

export default FAQ;
